import { CxEvent, facadeFactory, provideDefaultConfigFactory, provideDefaultConfig, AuthGuard } from '@spartacus/core';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, NgModule } from '@angular/core';
import { CmsPageGuard, PageLayoutComponent, getAddressNumbers } from '@spartacus/storefront';
import * as i1 from '@angular/router';
import { RouterModule } from '@angular/router';
import { CART_BASE_FEATURE, ORDER_ENTRIES_CONTEXT } from '@spartacus/cart/base/root';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * An abstract event for all the order events.
 */
class OrderEvent extends CxEvent {}
/**
 * Indicates that a user has successfully placed an order.
 */
class OrderPlacedEvent extends OrderEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'OrderPlacedEvent';
  }
}
/**
 * Indicates that a user has successfully placed scheduled an order.
 */
class ReplenishmentOrderScheduledEvent extends OrderEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'ReplenishmentOrderScheduledEvent';
  }
}
/**
 * Indicates that a user has click on 'Download Invoices' button on Order details page
 */
class DownloadOrderInvoicesEvent extends CxEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'DownloadOrderInvoicesEvent';
  }
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ORDER_FEATURE = 'order';
const ORDER_CORE_FEATURE = 'orderCore';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function orderHistoryFacadeFactory() {
  return facadeFactory({
    facade: OrderHistoryFacade,
    feature: ORDER_CORE_FEATURE,
    methods: ['getOrderDetails', 'loadOrderDetails', 'clearOrderDetails', 'getOrderHistoryList', 'getOrderHistoryListLoaded', 'loadOrderList', 'clearOrderList', 'getConsignmentTracking', 'loadConsignmentTracking', 'clearConsignmentTracking', 'cancelOrder', 'getCancelOrderLoading', 'getCancelOrderSuccess', 'resetCancelOrderProcessState', 'getOrderDetailsLoading', 'getQueryParams'],
    async: true
  });
}
class OrderHistoryFacade {
  static {
    this.ɵfac = function OrderHistoryFacade_Factory(t) {
      return new (t || OrderHistoryFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OrderHistoryFacade,
      factory: () => orderHistoryFacadeFactory(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OrderHistoryFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: orderHistoryFacadeFactory
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function orderReturnRequestFacadeFactory() {
  return facadeFactory({
    facade: OrderReturnRequestFacade,
    feature: ORDER_CORE_FEATURE,
    methods: ['createOrderReturnRequest', 'getOrderReturnRequest', 'getOrderReturnRequestList', 'loadOrderReturnRequestDetail', 'loadOrderReturnRequestList', 'clearOrderReturnRequestList', 'getReturnRequestLoading', 'getReturnRequestSuccess', 'clearOrderReturnRequestDetail', 'cancelOrderReturnRequest', 'getCancelReturnRequestLoading', 'getCancelReturnRequestSuccess', 'resetCancelReturnRequestProcessState'],
    async: true
  });
}
class OrderReturnRequestFacade {
  static {
    this.ɵfac = function OrderReturnRequestFacade_Factory(t) {
      return new (t || OrderReturnRequestFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OrderReturnRequestFacade,
      factory: () => orderReturnRequestFacadeFactory(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OrderReturnRequestFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: orderReturnRequestFacadeFactory
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class OrderFacade {
  static {
    this.ɵfac = function OrderFacade_Factory(t) {
      return new (t || OrderFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OrderFacade,
      factory: () => (() => facadeFactory({
        facade: OrderFacade,
        feature: ORDER_CORE_FEATURE,
        methods: ['getOrderDetails', 'clearPlacedOrder', 'setPlacedOrder', 'placeOrder', 'getPickupEntries', 'getDeliveryEntries']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OrderFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: OrderFacade,
        feature: ORDER_CORE_FEATURE,
        methods: ['getOrderDetails', 'clearPlacedOrder', 'setPlacedOrder', 'placeOrder', 'getPickupEntries', 'getDeliveryEntries']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ReorderOrderFacade {
  static {
    this.ɵfac = function ReorderOrderFacade_Factory(t) {
      return new (t || ReorderOrderFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ReorderOrderFacade,
      factory: () => (() => facadeFactory({
        facade: ReorderOrderFacade,
        feature: ORDER_CORE_FEATURE,
        methods: ['reorder']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReorderOrderFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: ReorderOrderFacade,
        feature: ORDER_CORE_FEATURE,
        methods: ['reorder']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function replenishmentOrderHistoryFacadeFactory() {
  return facadeFactory({
    facade: ReplenishmentOrderHistoryFacade,
    feature: ORDER_CORE_FEATURE,
    methods: ['loadReplenishmentOrderDetails', 'getReplenishmentOrderDetails', 'getReplenishmentOrderDetailsLoading', 'getReplenishmentOrderDetailsSuccess', 'getReplenishmentOrderDetailsError', 'clearReplenishmentOrderDetails', 'cancelReplenishmentOrder', 'getCancelReplenishmentOrderLoading', 'getCancelReplenishmentOrderSuccess', 'getCancelReplenishmentOrderError', 'clearCancelReplenishmentOrderProcessState', 'getReplenishmentOrderHistoryList', 'getReplenishmentOrderHistoryListLoading', 'getReplenishmentOrderHistoryListError', 'getReplenishmentOrderHistoryListSuccess', 'loadReplenishmentOrderList', 'clearReplenishmentOrderList'],
    async: true
  });
}
class ReplenishmentOrderHistoryFacade {
  static {
    this.ɵfac = function ReplenishmentOrderHistoryFacade_Factory(t) {
      return new (t || ReplenishmentOrderHistoryFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ReplenishmentOrderHistoryFacade,
      factory: () => replenishmentOrderHistoryFacadeFactory(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReplenishmentOrderHistoryFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: replenishmentOrderHistoryFacadeFactory
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ScheduledReplenishmentOrderFacade {
  static {
    this.ɵfac = function ScheduledReplenishmentOrderFacade_Factory(t) {
      return new (t || ScheduledReplenishmentOrderFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ScheduledReplenishmentOrderFacade,
      factory: () => (() => facadeFactory({
        facade: ScheduledReplenishmentOrderFacade,
        feature: ORDER_CORE_FEATURE,
        methods: ['scheduleReplenishmentOrder']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScheduledReplenishmentOrderFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: ScheduledReplenishmentOrderFacade,
        feature: ORDER_CORE_FEATURE,
        methods: ['scheduleReplenishmentOrder']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Identifiers of outlets for the order consignment.
 */
var OrderOutlets;
(function (OrderOutlets) {
  OrderOutlets["ORDER_CONSIGNMENT"] = "cx-order-consignment";
  OrderOutlets["CONSIGNMENT_DELIVERY_INFO"] = "cx-order-consignment-delivery-info";
  OrderOutlets["SERVICE_DETAILS"] = "cx-service-details-card";
})(OrderOutlets || (OrderOutlets = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const recurrencePeriod = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY'
};
var DaysOfWeek;
(function (DaysOfWeek) {
  DaysOfWeek["MONDAY"] = "MONDAY";
  DaysOfWeek["TUESDAY"] = "TUESDAY";
  DaysOfWeek["WEDNESDAY"] = "WEDNESDAY";
  DaysOfWeek["THURSDAY"] = "THURSDAY";
  DaysOfWeek["FRIDAY"] = "FRIDAY";
  DaysOfWeek["SATURDAY"] = "SATURDAY";
  DaysOfWeek["SUNDAY"] = "SUNDAY";
})(DaysOfWeek || (DaysOfWeek = {}));
var ORDER_TYPE;
(function (ORDER_TYPE) {
  ORDER_TYPE["PLACE_ORDER"] = "PLACE_ORDER";
  ORDER_TYPE["SCHEDULE_REPLENISHMENT_ORDER"] = "SCHEDULE_REPLENISHMENT_ORDER";
})(ORDER_TYPE || (ORDER_TYPE = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultOrderRoutingConfig = {
  routing: {
    routes: {
      orders: {
        paths: ['my-account/orders']
      },
      orderDetails: {
        paths: ['my-account/order/:orderCode'],
        paramsMapping: {
          orderCode: 'code'
        }
      },
      orderGuest: {
        paths: ['guest/order/:orderCode'],
        paramsMapping: {
          orderCode: 'code'
        }
      },
      orderReturn: {
        paths: ['my-account/order/return/:orderCode'],
        paramsMapping: {
          orderCode: 'code'
        }
      },
      orderReturnConfirmation: {
        paths: ['my-account/order/return/confirmation/:orderCode'],
        paramsMapping: {
          orderCode: 'code'
        }
      },
      orderCancel: {
        paths: ['my-account/order/cancel/:orderCode'],
        paramsMapping: {
          orderCode: 'code'
        }
      },
      orderCancelConfirmation: {
        paths: ['my-account/order/cancel/confirmation/:orderCode'],
        paramsMapping: {
          orderCode: 'code'
        }
      },
      returnRequestDetails: {
        paths: ['my-account/return-request/:returnCode'],
        paramsMapping: {
          returnCode: 'rma'
        }
      },
      replenishmentOrders: {
        paths: ['my-account/my-replenishments']
      },
      replenishmentDetails: {
        paths: ['my-account/my-replenishment/:replenishmentOrderCode'],
        paramsMapping: {
          replenishmentOrderCode: 'replenishmentOrderCode'
        }
      },
      replenishmentConfirmation: {
        paths: ['replenishment/confirmation']
      },
      orderConfirmation: {
        paths: ['order-confirmation']
      }
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const OrderDetailsOrderEntriesContextToken = new InjectionToken('OrderDetailsOrderEntriesContext');
const OrderConfirmationOrderEntriesContextToken = new InjectionToken('OrderConfirmationOrderEntriesContext');
const USE_MY_ACCOUNT_V2_ORDER = new InjectionToken('feature flag to enable enhanced UI for Order related pages under My-Account', {
  providedIn: 'root',
  factory: () => false
});

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultOrderComponentsConfig() {
  const config = {
    featureModules: {
      [ORDER_FEATURE]: {
        cmsComponents: ['CancelOrderComponent', 'CancelOrderConfirmationComponent', 'ReturnOrderComponent', 'ReturnOrderConfirmationComponent', 'AccountOrderDetailsActionsComponent', 'AccountOrderDetailsItemsComponent', 'AccountOrderDetailsTotalsComponent', 'AccountOrderDetailsOverviewComponent', 'AccountOrderDetailsBillingComponent', 'AccountOrderDetailsGroupedItemsComponent', 'AccountOrderDetailsSimpleOverviewComponent', 'AccountOrderHistoryComponent', 'ReplenishmentDetailItemsComponent', 'AccountOrderDetailsReorderComponent', 'ReplenishmentDetailTotalsComponent', 'ReplenishmentDetailShippingComponent', 'ReplenishmentDetailActionsComponent', 'ReplenishmentDetailOrderHistoryComponent', 'AccountReplenishmentHistoryComponent', 'ReturnRequestOverviewComponent', 'ReturnRequestItemsComponent', 'ReturnRequestTotalsComponent', 'OrderReturnRequestListComponent', 'OrderConfirmationThankMessageComponent', 'OrderConfirmationItemsComponent', 'OrderConfirmationTotalsComponent', 'OrderConfirmationOverviewComponent', 'OrderConfirmationShippingComponent', 'OrderConfirmationBillingComponent', 'OrderConfirmationContinueButtonComponent', 'ReplenishmentConfirmationMessageComponent', 'ReplenishmentConfirmationOverviewComponent', 'ReplenishmentConfirmationItemsComponent', 'ReplenishmentConfirmationTotalsComponent', 'MyAccountViewOrderComponent'],
        dependencies: [CART_BASE_FEATURE]
      },
      // by default core is bundled together with components
      [ORDER_CORE_FEATURE]: ORDER_FEATURE
    }
  };
  return config;
}
class OrderRootModule {
  static {
    this.ɵfac = function OrderRootModule_Factory(t) {
      return new (t || OrderRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: OrderRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultOrderComponentsConfig), provideDefaultConfig(defaultOrderRoutingConfig)],
      imports: [RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          pageLabel: 'order',
          cxRoute: 'orderGuest'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderDetails',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: OrderDetailsOrderEntriesContextToken
          }
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderCancel'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderCancelConfirmation'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderReturn'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderReturnConfirmation'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orders'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'replenishmentDetails'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'replenishmentOrders'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'returnRequestDetails'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderConfirmation',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: OrderConfirmationOrderEntriesContextToken
          }
        }
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OrderRootModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          pageLabel: 'order',
          cxRoute: 'orderGuest'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderDetails',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: OrderDetailsOrderEntriesContextToken
          }
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderCancel'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderCancelConfirmation'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderReturn'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderReturnConfirmation'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orders'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'replenishmentDetails'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'replenishmentOrders'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'returnRequestDetails'
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'orderConfirmation',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: OrderConfirmationOrderEntriesContextToken
          }
        }
      }])],
      providers: [provideDefaultConfigFactory(defaultOrderComponentsConfig), provideDefaultConfig(defaultOrderRoutingConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ORDER_NORMALIZER = new InjectionToken('OrderNormalizer');
const REPLENISHMENT_ORDER_NORMALIZER = new InjectionToken('ReplenishmentOrderNormalizer');
const REORDER_ORDER_NORMALIZER = new InjectionToken('ReorderOrderNormalizer');
const ORDER_HISTORY_NORMALIZER = new InjectionToken('OrderHistoryNormalizer');
const CONSIGNMENT_TRACKING_NORMALIZER = new InjectionToken('ConsignmentTrackingNormalizer');
const ORDER_RETURN_REQUEST_NORMALIZER = new InjectionToken('OrderReturnRequestNormalizer');
const ORDER_RETURN_REQUEST_INPUT_SERIALIZER = new InjectionToken('OrderReturnRequestInputSerializer');
const ORDER_RETURNS_NORMALIZER = new InjectionToken('OrderReturnsNormalizer');
const REPLENISHMENT_ORDER_HISTORY_NORMALIZER = new InjectionToken('ReplenishmentOrderHistoryNormalizer');
const REPLENISHMENT_ORDER_FORM_SERIALIZER = new InjectionToken('ReplenishmentOrderFormSerializer');

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Get card for delivery address
 */
function deliveryAddressCard(textTitle, textPhone, textMobile, deliveryAddress, countryName) {
  if (!countryName) {
    countryName = deliveryAddress?.country?.name;
  }
  let region = '';
  if (deliveryAddress && deliveryAddress.region && deliveryAddress.region.isocode) {
    region = deliveryAddress.region.isocode + ', ';
  }
  const numbers = getAddressNumbers(deliveryAddress, textPhone, textMobile);
  let fullName;
  if (deliveryAddress.firstName && deliveryAddress.lastName) {
    fullName = deliveryAddress.firstName + ' ' + deliveryAddress.lastName;
  } else if (deliveryAddress.firstName) {
    fullName = deliveryAddress.firstName;
  } else if (deliveryAddress.lastName) {
    fullName = deliveryAddress.lastName;
  }
  return {
    title: textTitle,
    textBold: fullName,
    text: [deliveryAddress.line1, deliveryAddress.line2, deliveryAddress.town + ', ' + region + countryName, deliveryAddress.postalCode, numbers]
  };
}
/**
 * Get card for delivery mode
 */
function deliveryModeCard(textTitle, deliveryMode) {
  return {
    title: textTitle,
    textBold: deliveryMode.name,
    text: [deliveryMode.description, deliveryMode.deliveryCost?.formattedValue ? deliveryMode.deliveryCost?.formattedValue : '']
  };
}
/**
 * Get card for payment method
 */
function paymentMethodCard(textTitle, textExpires, paymentDetails) {
  return {
    title: textTitle,
    text: [paymentDetails.cardType?.name, paymentDetails.accountHolderName, paymentDetails.cardNumber, textExpires]
  };
}
/**
 * Get card for billing address
 */
function billingAddressCard(textTitle, textBillTo, paymentDetails) {
  const region = paymentDetails.billingAddress?.region?.isocode ? paymentDetails.billingAddress?.region?.isocode + ', ' : '';
  return {
    title: textTitle,
    text: [textBillTo, paymentDetails.billingAddress?.firstName + ' ' + paymentDetails.billingAddress?.lastName, paymentDetails.billingAddress?.line1, paymentDetails.billingAddress?.town + ', ' + region + paymentDetails.billingAddress?.country?.isocode, paymentDetails.billingAddress?.postalCode]
  };
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/** AUGMENTABLE_TYPES_END */

/**
 * Generated bundle index. Do not edit.
 */

export { CONSIGNMENT_TRACKING_NORMALIZER, DaysOfWeek, DownloadOrderInvoicesEvent, ORDER_CORE_FEATURE, ORDER_FEATURE, ORDER_HISTORY_NORMALIZER, ORDER_NORMALIZER, ORDER_RETURNS_NORMALIZER, ORDER_RETURN_REQUEST_INPUT_SERIALIZER, ORDER_RETURN_REQUEST_NORMALIZER, ORDER_TYPE, OrderConfirmationOrderEntriesContextToken, OrderDetailsOrderEntriesContextToken, OrderEvent, OrderFacade, OrderHistoryFacade, OrderOutlets, OrderPlacedEvent, OrderReturnRequestFacade, OrderRootModule, REORDER_ORDER_NORMALIZER, REPLENISHMENT_ORDER_FORM_SERIALIZER, REPLENISHMENT_ORDER_HISTORY_NORMALIZER, REPLENISHMENT_ORDER_NORMALIZER, ReorderOrderFacade, ReplenishmentOrderHistoryFacade, ReplenishmentOrderScheduledEvent, ScheduledReplenishmentOrderFacade, USE_MY_ACCOUNT_V2_ORDER, billingAddressCard, defaultOrderComponentsConfig, deliveryAddressCard, deliveryModeCard, orderHistoryFacadeFactory, orderReturnRequestFacadeFactory, paymentMethodCard, recurrencePeriod, replenishmentOrderHistoryFacadeFactory };
